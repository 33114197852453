exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

